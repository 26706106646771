import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as culture from "./culture.module.scss"

const Culture = ({ pageContext, location }) => {
  const pageTitle = "Culture"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h1>Culture</h1>

        <p>Through our Company culture, we are committed to associate engagement, community enhancement and work/life balance. We offer progressive careers in:</p>
        
        <ul className={culture.list}>
          <li>Sales</li>
          <li>E-commerce</li>
          <li>Accounting &amp; Financing</li>
          <li>Marketing</li>
          <li>Graphic Design</li>
          <li>Social Media</li>
          <li>Web Design &amp; Development</li>
          <li>Supply Chain &amp; Distribution</li>
          <li>Customer Relations</li>
          <li>Information Technology</li>
        </ul>

        <img src={
            require("../../assets/images/careers/culture/culture-employee-photo.jpg")
              .default
          }
          alt="Employee happily working at desk"
          className={culture.img}
        />

        <p><a class="CTAblack" href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst=" title="View Open Positions">View Open Positions</a></p>
      </div>
    </Layout>
  )
}

export default Culture
